<template>
    <div class="insight_waiilist">
        <a-row style="background-color: #fff;">
            <a-col :md="{ span: 22 }" :xs="{ span: 24 }">
                <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
            </a-col>
            <a-col :md="{ span: 2 }" :xs="{ span: 24 }" style="padding-top: 6px; padding-right: 5px;">
                <a-button style="margin-right: 5px;" @click="turnToStoreList">{{ $t('insight.go_back') }}</a-button>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 0px; height: auto;">
                <a-card :title="$t('insight.dashboard') + (loading_success_count > 0 ? ' (Total ' + loading_success_count + ' stores)' : '')" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange" v-model:value="log_date" :format="dateFormat"></a-range-picker>
                        <a-select v-if="false" v-model:value="log_type" style="width: 120px; margin-left: 10px;" @change="handleSelectChange('log_type', $event)">
                            <a-select-option value="all">
                                {{ $t('common.all') }}
                            </a-select-option>
                            <a-select-option value="message">
                                {{ $t('common.message') }}
                            </a-select-option>
                            <a-select-option value="voice">
                                {{ $t('common.voice') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange" v-model:value="log_date" :format="dateFormat"></a-range-picker>
                        <a-select v-if="false" v-model:value="log_type" style="width: 120px" @change="handleSelectChange('log_type', $event)">
                            <a-select-option value="all">
                                {{ $t('common.all') }}
                            </a-select-option>
                            <a-select-option value="message">
                                {{ $t('common.message') }}
                            </a-select-option>
                            <a-select-option value="voice">
                                {{ $t('common.voice') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div>
                        <a-progress :stroke-color="{ '0%': '#108ee9', '100%': '#87d068',}" :percent="loading_percent"></a-progress>
                    </div>
                    <a-row :gutter="is_mobile ? 2 : 16" style="margin-top: 5px; overflow: auto;" :style="{ height: dashboard_cards.length > 0 ? '270px' : '' }">
                        <a-col :span="is_mobile ? 12 : 6" v-for="(item, index) in dashboard_cards" v-bind:key="item.type" :style="{marginTop: index > 3 ? '5px': '0px'}">
                            <a-card :bodyStyle="{'padding-left': '10px', height: index < 4 ? '100px' : '130px'}" style="width: 100%;background-color: #eee;">
                                <a-card-meta :title="item.name">
                                    <template #avatar v-if="!is_mobile">
                                        <ScheduleOutlined style="font-size: 28px;" v-if="item.icon == 'schedule'" />
                                        <TeamOutlined style="font-size: 28px;" v-if="item.icon == 'team'" />
                                        <ContactsOutlined style="font-size: 28px;" v-if="item.icon == 'contacts'" />
                                        <PayCircleOutlined style="font-size: 28px;" v-if="item.icon == 'pay-circle'" />
                                    </template>
                                    <template #description>
                                        <h2 v-if="index < 4" style="display: inline-block;">{{ item.icon == 'pay-circle' ? '$' + item.value : item.value }}</h2>
                                        <div v-else>
                                            <h4 style="margin-bottom: 0;">{{ 'Count: ' + item.value }}</h4>
                                            <h4 style="margin-bottom: 0;" v-if="item.subvalue">{{ 'Segments Count: ' + item.subvalue }}</h4>
                                            <h3 style="margin-bottom: 0;">{{ 'Cost: $' + item.cost }}</h3>
                                        </div>
                                    </template>
                                </a-card-meta>
                            </a-card>
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 5px; height: auto;">
                <a-card :title="$t('insight.store_list')" :bordered="false" style="width: 100%; height: 100%;">
                    <div v-if="!is_mobile">
                        <a-table style="margin-top: 10px;" :loading="is_loading" rowKey="user_name" :columns="columns" :data-source="dashboard_list" :pagination="false">
                            <template #bodyCell="{ column, text }">
                                <template v-if="column.key === 'status'">
                                    <div style="color: #ff4d4f" v-if="text == 0">{{ $t('insight.sending') }}</div>
                                    <div style="color: #1aad19" v-if="text == 1">{{ $t('insight.delivered') }}</div>
                                    <div style="color: #989898" v-if="text == 2">{{ $t('insight.failed') }}</div>
                                </template>
                                <template v-else>
                                    <span>
                                        {{ text }}
                                    </span>
                                </template>
                            </template>
                        </a-table>
                    </div>
                    <div v-else>
                        <a-card style="width: 100%; margin-top: 5px;" v-for="item in dashboard_list" v-bind:key="item.user_name">
                            <p>Merchant Name: {{ item.sname }}</p>
                            <p>Message Success Count: {{ item.total_message_count }}</p>
                            <p>Segment Count: {{ item.total_segments }}</p>
                            <p>Voice Success Count: {{ item.total_voices }}</p>
                            <p>Total Cost: {{ item.total_cost }}</p>
                        </a-card>
                        <div v-if="is_loading" style="width: 100%; text-align: center; padding: 20px;">
                            <a-spin></a-spin>
                        </div>
                    </div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
    import moment from 'moment-timezone';
    import { h } from 'vue';
    import dayjs from 'dayjs';

    import {
        MailOutlined,
        TeamOutlined,
        GlobalOutlined,
        ClockCircleOutlined,
        ScheduleOutlined,
        ContactsOutlined,
        PayCircleOutlined,
    } from '@ant-design/icons-vue';

    export default {
        name: "insight_notification",
        components: {
            TeamOutlined,
            ScheduleOutlined,
            ContactsOutlined,
            PayCircleOutlined,
        },
        data() {
            return {
                items: [
                {
                        key: 'summary',
                        icon: () => h(GlobalOutlined),
                        label: this.$t('insight.summary'),
                        title: 'Summary',
                    },
                    {
                        key: 'waitlist',
                        icon: () => h(TeamOutlined),
                        label: this.$t('insight.waitlist'),
                        title: 'Waitlist',
                    },
                    {
                        key: 'reserve',
                        icon: () => h(ClockCircleOutlined),
                        label: this.$t('insight.reserve'),
                        title: 'Reserve',
                    },
                    {
                        key: 'notification',
                        icon: () => h(MailOutlined),
                        label: this.$t('insight.notification'),
                        title: 'Notification',
                    },
                ],
                sid: this.$store.state.app.select_sid,
                store_options: [],
                chain_id: this.$store.state.app.chain_id,
                cluster: this.$store.state.app.cluster,
                timezone: this.$store.state.app.timezone,
                is_mobile: this.$store.state.app.is_mobile,
                current: ['notification'],
                log_platform: 'bird',
                log_type: 'all',
                log_date: [],
                log_list: [],
                page: 1,
                is_loading: false,
                loading_percent: 0,
                loading_success_count: 0,
                loading_total_count: 0,
                platform: 'local',
                log_total_page: 0,
                log_total_count: 0,
                columns: [
                    {
                        title: 'Store Name',
                        dataIndex: 'sname',
                        width: 150
                    },
                    {
                        title: 'Email',
                        dataIndex: 'user_name',
                        width: 150
                    },
                    {
                        title: 'Message Success Count',
                        dataIndex: 'total_message_count',
                        width: 180,
                    },
                    {
                        title: 'Segment Count',
                        dataIndex: 'total_segments',
                        width: 150,
                    },
                    {
                        title: 'Voice Success Count',
                        dataIndex: 'total_voices',
                        width: 150,
                    },
                    {
                        title: 'Total Cost',
                        dataIndex: 'total_cost',
                        width: 150,
                    },
                ],
                
                dateFormat: 'YYYY-MM-DD',
                moment: moment,

                dashboard_cards: [],
                dashboard_list: [],
                store_dict: {},
            }
        },
        async beforeMount() {
            let sdate = dayjs(moment().subtract(30, 'days').format(this.dateFormat), this.dateFormat);
            let edate = dayjs(moment().subtract(0, 'days').format(this.dateFormat), this.dateFormat);

            let s_year = moment().add(-30, 'days').format('YYYY');
            let e_year = moment().format('YYYY');
            if (s_year != e_year) {
                sdate = dayjs(moment().startOf('month').format(this.dateFormat), this.dateFormat);
            }

            this.log_date = [sdate, edate];
        },
        async mounted() {
            this.do_get_store_list();
        },
        watch: {
            current() {
                if (this.current == 'summary') {
                    this.$router.push({
                        path: '/insight/home'
                    });
                } else if (this.current == 'reserve') {
                    this.$router.push({
                        path: '/insight/reserve'
                    });
                } else if (this.current == 'waitlist') {
                    this.$router.push({
                        path: '/insight/waitlist'
                    });
                }
            },
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                get_notification_dashbaord: 'insight/get_notification_dashbaord',
                getStoreList: 'app/getStoreList',
            }),
            ...mapMutations({
                setSelectSid: 'app/APP_SET_SELECT_SID'
            }),
            handleChangeStore() {
                this.setSelectSid(this.sid);
                this.request_data();
            },
            async do_get_store_list() {
                let store_list = await this.getStoreList({
                    chain_id: this.chain_id,
                    page: 1,
                    size: 0
                });

                this.store_options = store_list.map(item => {
                    this.store_dict[item.sid] = item;
                    return {
                        value: item.sid,
                        label: item.nick_name,
                    };
                });

                this.loading_total_count = store_list.length;

                if (!this.sid) {
                    this.setSelectSid(this.store_options[0] && this.store_options[0].value || '');
                    this.sid = this.store_options[0] && this.store_options[0].value || '';
                }

                this.request_data();
            },
            async request_data() {
                this.dashboard_cards = [];
                this.dashboard_list = [];
                this.is_loading = true;

                for (let i = 0; i < this.store_options.length; i++) {
                    let sid = this.store_options[i].value;
                    let item = await this.request_dashboard(sid);

                    if (item) {
                        this.loading_success_count = this.loading_success_count + 1;
                        this.loading_percent = ((this.loading_success_count / this.loading_total_count) * 100).toFixed(2) * 1;
                        
                        this.merge_cards_data(item);
                        this.revert_cards_data(item, sid);
                    }
                }

                this.is_loading = false;
            },
            merge_cards_data(cards) {
                let old_cards = JSON.parse(JSON.stringify(this.dashboard_cards));

                for (let i = 0; i < cards.length; i++) {
                    let item = cards[i];
                    let got = false;

                    for (let j = 0; j < old_cards.length; j++) {
                        let old_item = old_cards[j];
                        if (item.type == old_item.type) {
                            old_item.value += item.value;
                            
                            if (old_item.subvalue !== null) {
                                old_item.subvalue += item.subvalue;
                            }

                            old_item.cost += item.cost;
                            old_item.cost = old_item.cost.toFixed(4) * 1;

                            got = true;
                            break;
                        }
                    }

                    if (!got) {
                        old_cards.push(item);
                    }
                }

                this.dashboard_cards = old_cards;
            },
            revert_cards_data(cards, sid) {
                let item = {
                    sname: this.store_dict[sid].nick_name,
                    user_name: this.store_dict[sid].user_name,
                    total_segments: 0,
                };
                for (let i = 0; i < cards.length; i++) {
                    if (cards[i].type == 'total_count' || cards[i].type == 'total_message_count' || cards[i].type == 'total_voices' || cards[i].type == 'total_cost') {
                        item[cards[i].type] = cards[i].icon == 'pay-circle' ? '$' + cards[i].value : cards[i].value;
                    } else {
                        item[cards[i].type] = {
                            value: cards[i].value,
                            subvalue: cards[i].subvalue,
                            cost: cards[i].icon == 'pay-circle' ? '$' + cards[i].cost : null
                        };

                        if (cards[i].type == 'local_sms' || cards[i].type == 'national_sms') {
                            item.total_segments = item.total_segments + cards[i].subvalue;
                        }
                    }
                }

                this.dashboard_list.push(item);
            },
            async request_dashboard(sid) {
                let dashboard_data = await this.get_notification_dashbaord({
                    sid: sid,
                    sdate: dayjs(this.log_date[0]).format(this.dateFormat),
                    edate: dayjs(this.log_date[1]).format(this.dateFormat),
                    type: this.log_type
                });

                if (dashboard_data.code === 100000) {
                    let cards = dashboard_data.data.cards;

                    return cards;
                }

                return false;
            },
            handleSelectChange(type, value) {
                this[type] = value;
                this.request_data();
            },
            onDateChange() {
                this.request_data();
            },
            turnToStoreList() {
                this.$router.push({
                    path: '/insight/notification'
                });
            }
        }
    }
</script>

<style>
    .insight_waiilist .ant-card-body {
        padding-left: 30px;
    }
    .insight_waiilist p {
        margin-bottom: 2px;
    }
    .insight_select_card {
        border: 1px solid #000;
        border-radius: 5px;
        padding: 5px 10px 5px 10px;
        cursor: pointer;
    }
    .insight_select_card.current {
        border: 1px solid #fe7f03;
    }

    .insight_select_card .sub_text {
        display: inline-block;
        font-size: 12px;
    }

    .insight_select_card .sub_text {
        display: inline-block;
        font-size: 10px;
    }

    .insight_select_card .sub_text span {
        display: block;
    }
</style>