<template>
    <div class="insight_waiilist">
        <a-row style="background-color: #fff;">
            <a-col :md="{ span: 16 }" :xs="{ span: 24 }">
                <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
            </a-col>
            <a-col :md="{ span: 8 }" :xs="{ span: 24 }" style="padding-top: 6px; padding-right: 5px;" v-if="store_options.length > 0">
                <a-select
                    v-model:value="sid"
                    :options="store_options"
                    style="width: 300px"
                    @change="handleChangeStore"
                >
                </a-select>
                <a-button style="margin-left: 5px;" @click="turnToAllStore">{{ $t('insight.all_store') }}</a-button>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 0px; height: auto;">
                <a-card :title="$t('insight.dashboard')" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange" v-model:value="log_date" :format="dateFormat"></a-range-picker>
                        <a-select v-if="false" v-model:value="log_type" style="width: 120px; margin-left: 10px;" @change="handleSelectChange('log_type', $event)">
                            <a-select-option value="all">
                                {{ $t('common.all') }}
                            </a-select-option>
                            <a-select-option value="message">
                                {{ $t('common.message') }}
                            </a-select-option>
                            <a-select-option value="voice">
                                {{ $t('common.voice') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange" v-model:value="log_date" :format="dateFormat"></a-range-picker>
                        <a-select v-if="false" v-model:value="log_type" style="width: 120px" @change="handleSelectChange('log_type', $event)">
                            <a-select-option value="all">
                                {{ $t('common.all') }}
                            </a-select-option>
                            <a-select-option value="message">
                                {{ $t('common.message') }}
                            </a-select-option>
                            <a-select-option value="voice">
                                {{ $t('common.voice') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <a-row :gutter="is_mobile ? 2 : 16" style="margin-top: 5px; overflow: auto;" :style="{ height: dashboard_cards.length > 0 ? '270px' : '' }">
                        <a-col :span="is_mobile ? 12 : 6" v-for="(item, index) in dashboard_cards" v-bind:key="item.type" :style="{marginTop: index > 3 ? '5px': '0px'}">
                            <a-card :bodyStyle="{'padding-left': '10px', height: index < 4 ? '100px' : '130px'}" style="width: 100%;background-color: #eee;">
                                <a-card-meta :title="item.name">
                                    <template #avatar v-if="!is_mobile">
                                        <ScheduleOutlined style="font-size: 28px;" v-if="item.icon == 'schedule'" />
                                        <TeamOutlined style="font-size: 28px;" v-if="item.icon == 'team'" />
                                        <ContactsOutlined style="font-size: 28px;" v-if="item.icon == 'contacts'" />
                                        <PayCircleOutlined style="font-size: 28px;" v-if="item.icon == 'pay-circle'" />
                                    </template>
                                    <template #description>
                                        <h2 v-if="index < 4" style="display: inline-block;">{{ item.icon == 'pay-circle' ? '$' + item.value : item.value }}</h2>
                                        <div v-else>
                                            <h4 style="margin-bottom: 0;">{{ 'Count: ' + item.value }}</h4>
                                            <h4 style="margin-bottom: 0;" v-if="item.subvalue">{{ 'Segments Count: ' + item.subvalue }}</h4>
                                            <h3 style="margin-bottom: 0;">{{ 'Cost: $' + item.cost }}</h3>
                                        </div>
                                    </template>
                                </a-card-meta>
                            </a-card>
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 5px; height: auto;">
                <a-card :title="$t('insight.notification_log')" :bordered="false" style="width: 100%; height: 100%;">
                    <div>
                        <a-radio-group v-model:value="platform" @change="handelChangePlatform">
                            <a-radio-button value="local">
                                Local
                            </a-radio-button>
                            <a-radio-button value="international">
                                International
                            </a-radio-button>
                        </a-radio-group>
                    </div>
                    <div v-if="!is_mobile">
                        <a-table style="margin-top: 10px;" :scroll="{ x: 1400 }" :loading="is_loading" rowKey="idx" :columns="columns" :data-source="log_list" :pagination="false">
                            <template #bodyCell="{ column, text }">
                                <template v-if="column.key === 'status'">
                                    <div style="color: #ff4d4f" v-if="text == 0">{{ $t('insight.sending') }}</div>
                                    <div style="color: #1aad19" v-if="text == 1">{{ $t('insight.delivered') }}</div>
                                    <div style="color: #989898" v-if="text == 2">{{ $t('insight.failed') }}</div>
                                </template>
                                <template v-else>
                                    <span>
                                        {{ text }}
                                    </span>
                                </template>
                            </template>
                        </a-table>
                        <a-pagination style="margin-top: 10px;" v-model="page" :total="log_total_count" :pageSize="20" @change="changePage" show-less-items />
                    </div>
                    <div v-else>
                        <a-card style="width: 100%; margin-top: 5px;" v-for="item in log_list" v-bind:key="item.idx">
                            <p>To: {{ item.to_phone_num }}</p>
                            <p>{{ $t('common.message')}}: {{ item.message }}</p>
                            <p>{{ $t('common.status')}}: {{ item.platform_status }}</p>
                        </a-card>
                        <div v-if="is_loading" style="width: 100%; text-align: center; padding: 20px;">
                            <a-spin></a-spin>
                        </div>
                        <a-button v-if="page < log_total_page" @click="doLoadMore" style="width: 100%; margin-top: 5px;">Load more</a-button>
                    </div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
    import moment from 'moment-timezone';
    import { h } from 'vue';
    import dayjs from 'dayjs';

    import {
        MailOutlined,
        TeamOutlined,
        GlobalOutlined,
        ClockCircleOutlined,
        ScheduleOutlined,
        ContactsOutlined,
        PayCircleOutlined,
    } from '@ant-design/icons-vue';

    export default {
        name: "insight_notification",
        components: {
            TeamOutlined,
            ScheduleOutlined,
            ContactsOutlined,
            PayCircleOutlined,
        },
        data() {
            return {
                items: [
                {
                        key: 'summary',
                        icon: () => h(GlobalOutlined),
                        label: this.$t('insight.summary'),
                        title: 'Summary',
                    },
                    {
                        key: 'waitlist',
                        icon: () => h(TeamOutlined),
                        label: this.$t('insight.waitlist'),
                        title: 'Waitlist',
                    },
                    {
                        key: 'reserve',
                        icon: () => h(ClockCircleOutlined),
                        label: this.$t('insight.reserve'),
                        title: 'Reserve',
                    },
                    {
                        key: 'notification',
                        icon: () => h(MailOutlined),
                        label: this.$t('insight.notification'),
                        title: 'Notification',
                    },
                ],
                sid: this.$store.state.app.select_sid,
                store_options: [],
                chain_id: this.$store.state.app.chain_id,
                cluster: this.$store.state.app.cluster,
                timezone: this.$store.state.app.timezone,
                is_mobile: this.$store.state.app.is_mobile,
                current: ['notification'],
                log_platform: 'bird',
                log_type: 'all',
                log_date: [],
                log_list: [],
                page: 1,
                is_loading: false,
                platform: 'local',
                log_total_page: 0,
                log_total_count: 0,
                columns: [
                    {
                        title: '#',
                        dataIndex: 'idx',
                        width: 75,
                        fixed: 'left',
                    },
                    {
                        title: this.$t('insight.to_phone'),
                        dataIndex: 'to_phone_num',
                        width: 150
                    },
                    {
                        title: this.$t('insight.from_phone'),
                        dataIndex: 'from_phone_num',
                        width: 150
                    },
                    {
                        title: this.$t('common.message'),
                        dataIndex: 'message',
                        width: 200,
                        ellipsis: true,
                    },
                    {
                        title: this.$t('insight.segments'),
                        dataIndex: 'segments',
                        width: 120
                    },
                    {
                        title: this.$t('common.status'),
                        dataIndex: 'status',
                        key: 'status',
                        width: 100
                    },
                    {
                        title: this.$t('common.action'),
                        dataIndex: 'action',
                        width: 120
                    },
                    {
                        title: 'Country',
                        dataIndex: 'country',
                    },
                    {
                        title: this.$t('common.create_time'),
                        dataIndex: 'create_time',
                        width: 150
                    },
                    {
                        title: 'Cost/Segment',
                        dataIndex: 'cost',
                        width: 150
                    },
                    {
                        title: 'Total Costs',
                        dataIndex: 'price',
                        fixed: 'right',
                    },
                ],
                
                dateFormat: 'YYYY-MM-DD',
                moment: moment,

                dashboard_cards: [],
                dashboad_loading: false,

                mapper: {
                    dashboard: {
                        total_count: {
                            text: this.$t('insight.total_count'),
                            icon: 'team'
                        },
                        total_message_count: {
                            text: this.$t('insight.total_message_count'),
                            icon: 'team'
                        },
                        total_segments: {
                            text: this.$t('insight.total_segments_count'),
                            icon: 'schedule'
                        },
                        total_voices: {
                            text: this.$t('insight.total_voice_count'),
                            icon: 'contacts'
                        }
                    }
                }
            }
        },
        async beforeMount() {
            let sdate = dayjs(moment().subtract(30, 'days').format(this.dateFormat), this.dateFormat);
            let edate = dayjs(moment().subtract(0, 'days').format(this.dateFormat), this.dateFormat);

            let s_year = moment().add(-30, 'days').format('YYYY');
            let e_year = moment().format('YYYY');
            if (s_year != e_year) {
                sdate = dayjs(moment().startOf('month').format(this.dateFormat), this.dateFormat);
            }

            this.log_date = [sdate, edate];
        },
        async mounted() {
            this.do_get_store_list();
        },
        watch: {
            current() {
                if (this.current == 'summary') {
                    this.$router.push({
                        path: '/insight/home'
                    });
                } else if (this.current == 'reserve') {
                    this.$router.push({
                        path: '/insight/reserve'
                    });
                } else if (this.current == 'waitlist') {
                    this.$router.push({
                        path: '/insight/waitlist'
                    });
                }
            },
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                get_notification_list: "insight/get_notification_list",
                get_notification_national_list: "insight/get_notification_national_list",
                get_notification_dashbaord: 'insight/get_notification_dashbaord',
                getStoreList: 'app/getStoreList',
            }),
            ...mapMutations({
                setSelectSid: 'app/APP_SET_SELECT_SID'
            }),
            doLoadMore() {
                this.page += 1;
                this.request_data();
            },
            handleChangeStore() {
                this.setSelectSid(this.sid);
                this.request_data();
            },
            async do_get_store_list() {
                let store_list = await this.getStoreList({
                    chain_id: this.chain_id,
                    page: 1,
                    size: 0
                });

                this.store_options = store_list.map(item => {
                    return {
                        value: item.sid,
                        label: item.nick_name,
                    };
                });

                if (!this.sid) {
                    this.setSelectSid(this.store_options[0] && this.store_options[0].value || '');
                    this.sid = this.store_options[0] && this.store_options[0].value || '';
                }

                this.request_data();
            },
            async request_data() {
                this.request_dashboard();

                if (this.platform == 'local') {
                    this.request_notification_list();
                } else {
                    this.request_notification_national_list();
                }
            },
            handelChangePlatform() {
                if (this.platform == 'local') {
                    this.request_notification_list();
                } else {
                    this.request_notification_national_list();
                }
            },
            async request_dashboard() {
                this.dashboad_loading = true;
                let dashboard_data = await this.get_notification_dashbaord({
                    sid: this.sid,
                    sdate: dayjs(this.log_date[0]).format(this.dateFormat),
                    edate: dayjs(this.log_date[1]).format(this.dateFormat),
                    type: this.log_type
                });

                if (dashboard_data.code === 100000) {
                    this.dashboard_cards = dashboard_data.data.cards;
                }

                this.dashboad_loading = false;
            },
            async request_notification_list() {
                this.is_loading = true;
                let log_data = await this.get_notification_list({
                    sid: this.sid,
                    sdate: dayjs(this.log_date[0]).format(this.dateFormat),
                    edate: dayjs(this.log_date[1]).format(this.dateFormat),
                    type: this.log_type,
                    page: this.page,
                    page_size: 20
                });

                if (log_data.code === 100000) {
                    let { list, total_page, total_count } = log_data.data;
                    
                    if (this.is_mobile) {
                        this.log_list = this.log_list.concat(list);
                    } else {
                        this.log_list = list;
                    }
                    this.log_total_page = total_page;
                    this.log_total_count = total_count;
                } else {
                    this.$message.error(log_data.msg);
                }

                this.is_loading = false;
            },
            async request_notification_national_list() {
                this.is_loading = true;
                let log_data = await this.get_notification_national_list({
                    sid: this.sid,
                    sdate: dayjs(this.log_date[0]).format(this.dateFormat),
                    edate: dayjs(this.log_date[1]).format(this.dateFormat),
                    type: this.log_type,
                    page: this.page,
                    page_size: 20
                });
                if (log_data.code === 100000) {
                    let { list, total_page, total_count } = log_data.data;
                    this.log_list = list;
                    this.log_total_page = total_page;
                    this.log_total_count = total_count;
                } else {
                    this.$message.error(log_data.msg);
                }
                this.is_loading = false;
            },
            handleSelectChange(type, value) {
                this[type] = value;
                this.request_data();
            },
            onDateChange() {
                this.request_data();
            },
            changePage(page) {
                this.page = page;

                if (this.platform == 'local') {
                    this.request_notification_list()
                } else {
                    this.request_notification_national_list();
                }
            },
            turnToAllStore() {
                this.$router.push({
                    path: '/insight/summary_notification'
                });
            }
        }
    }
</script>

<style>
    .insight_waiilist .ant-card-body {
        padding-left: 30px;
    }
    .insight_waiilist p {
        margin-bottom: 2px;
    }
    .insight_select_card {
        border: 1px solid #000;
        border-radius: 5px;
        padding: 5px 10px 5px 10px;
        cursor: pointer;
    }
    .insight_select_card.current {
        border: 1px solid #fe7f03;
    }

    .insight_select_card .sub_text {
        display: inline-block;
        font-size: 12px;
    }

    .insight_select_card .sub_text {
        display: inline-block;
        font-size: 10px;
    }

    .insight_select_card .sub_text span {
        display: block;
    }
</style>