export default {
    common: {
        confirm: 'Confrim',
        cancel: 'Cancel',
        ok: 'OK',
        phone: 'Phone',
        email: 'Email',
        action: 'Action',
        search: 'Search',
        export: 'Export',
        date: 'Date',
        time: 'Time',
        name: 'Name',
        birthday: 'Birthday',
        all: 'All',
        message: 'Message',
        voice: 'Voice',
        create_time: 'Create Time',
        status: 'Status',
    },
    setting: {
        setting: 'Setting',
        general: 'General',
        account: 'Account',
        about: 'About',
        custom_language_label: 'Custom Language',
        logo_label: 'Logo',
        name_label: 'Group Name',
        version_label: 'Version',
        privacy_label: 'Privacy Statement',
        terms_label: 'Terms of Service',
        logout_btn: 'Logout',
        logout_tip: 'Logout current user?',
        notification_label: 'Notification permissions',
        authorized: 'Authorized',
        not_authorized: 'Not authorized',
    },
    insight: {
        insight: 'Insight',
        summary: 'Summary',
        waitlist: 'Waitlist',
        reserve: 'Reserve',
        notification: 'Notification',
        dashboard: 'Dashboard',
        snapshot: 'Snapshot',
        today: 'Today',
        this_week: 'This week',
        this_month: 'This month',
        this_year: 'This year',
        waiting_parties: 'Waiting Parties',
        current_waiting_time: 'Current Waiting Time',
        reserved_parties: 'Reserved Parties',
        current_serving_parties: 'Current Serving Parties',
        diners: 'diners',
        mins: 'mins',
        total_parties: 'Total Parties',
        seated: 'Seated',
        no_show: 'No Show',
        'no show': 'No Show',
        canceled: 'Canceled',
        reservation: 'Reservation',
        number_of_waitlists: 'Number Of Waitlists',
        number_of_reservations: 'Number Of Reservations',
        number_of_new_guests: 'Number Of New Guests',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly',
        currently_waiting: 'Currently Waiting',
        total_waitlist: 'Total Waitlist',
        seated_parties: 'Seated Parties',
        seated_waitlists: 'Seated Waitlists',
        total_waitlist_days: 'Total Waitlist Number (Based on Days of the Week)',
        seated_waitlists_days: 'Seated Waitlist Number (Based on Days of the Week)',
        no_show_days: 'No Show Number (Based on Days of the Week)',
        waitlist_source: 'Waitlist Source',
        via_qr_code: 'Via QR Code',
        via_tablet: 'Via Tablet',
        via_google: 'Via Google',
        via_ipad: 'Via iPad',
        via_phone: 'Via Phone',
        wait_time: 'Wait Time',
        average_wait_time: 'Average Wait Time',
        max_wait_time: 'Max Wait Time',
        wait_time_distribution: 'Wait Time Distribution',
        party_size: 'Party Size',
        most_popular_party_type: 'The Most Popular Party Type',
        waitlists_of_diffenrent_party_sizes: 'Waitlists Of Diffenrent Party Sizes',
        guest_number_of_party: 'Guest Number Of Party',
        waitlist_amount: 'Waitlist Amount',
        guests: 'Guests',
        waitlists: 'Waitlists',
        take_up: 'Take Up',
        today_rez: 'Today Rez',
        total_seated_rez: 'Total Seated Rez',
        rez_cancelled: 'Rez Cancelled',
        by_guests: 'by Guests',
        by_restaurant: 'by restaurant',
        today_online_rez: 'Today Online Rez',
        total_reservations: 'Total Reservations',
        seated_rez_number: 'Seated Rez Number',
        cancelled_by_restaurant: 'Cancelled by Restaurant',
        cancelled_by_guests: 'Cancelled by Guests',
        reservation_source: 'Reservation Source',
        online_rez: 'Online Rez',
        reservation_time: 'Reservation Time',
        average_in_advance: 'Average in Advance',
        mode: 'Mode',
        attendance: 'Attendance',
        total_reservation_number: 'Total Reservation Number',
        seated_reservation_number: 'Seated Reservation Number',
        by_days: '(Based on Days of the Week)',
        day: 'Day',
        reservation_of_diffenrent_party_sizes: 'Reservation Of Diffenrent Party Sizes',
        reservation_amount: 'Reservation Amount',
        total_count: 'Total count',
        total_message_count: 'Total Message Count',
        total_segments_count: 'Total Segments Count',
        total_voice_count: 'Total voice count',
        notification_log: 'Notification Log',
        sending: 'Sending',
        delivered: 'Delivered',
        failed: 'Failed',
        from_phone: 'From Phone',
        to_phone: 'To Phone',
        segments: 'Segments',
        all_store: 'All Store',
        go_back: 'Go Back',
        store_list: 'Store List',
    },
    locations: {
        locations: 'Locations',
        logo: 'Logo',
        nickname: 'Nickname',
        address: 'address',
        city: 'City',
        country: 'Country',
        edit_nickname: 'Edit nickname',
    },
    survey: {
        survey: 'Survey+',
        food_star: 'Food star',
        service_star: 'Service star',
        menu_star: 'Menu star',
        speed_star: 'Speed star',
        is_first_time: 'Is first time',
        frequency: 'Frequency',
        known_method: 'Known Method',
        favorite_food: 'Favorite food',
        suggestion: 'Suggestion',
        note: 'Merchant Note',
    }
};