export default {
    common: {
        confirm: '确认',
        cancel: '取消',
        ok: '确定',
        phone: '电话',
        email: '邮箱',
        action: '操作',
        search: '搜索',
        export: '导出',
        date: '日期',
        time: '时间',
        name: '姓名',
        birthday: '生日',
        all: '全部',
        message: '短信',
        voice: '电话',
        create_time: '创建时间',
        status: '状态',
    },
    setting: {
        setting: '设置',
        general: '通用',
        account: '账户',
        about: '关于',
        custom_language_label: '自定义语言',
        logo_label: '标志',
        name_label: '名称',
        version_label: '版本',
        privacy_label: '隐私声明',
        terms_label: '服务条款',
        logout_btn: '退出登录',
        logout_tip: '退出当前登录吗？',
        notification_label: '通知权限',
        authorized: '已授权',
        not_authorized: '未授权',
    },
    insight: {
        insight: '洞察',
        summary: '总览',
        waitlist: '排队',
        reserve: '预定',
        notification: '通知',
        dashboard: '仪表板',
        snapshot: '快照',
        today: '今日',
        this_week: '本周',
        this_month: '本月',
        this_year: '今年',
        waiting_parties: '排队桌数',
        current_waiting_time: '当前等待时长',
        reserved_parties: '预定桌数',
        current_serving_parties: '当前服务桌数',
        diners: '人',
        mins: '分钟',
        total_parties: '总桌数',
        seated: '入座',
        no_show: '过号',
        'no show': '过号',
        canceled: '取消',
        reservation: '预定',
        number_of_waitlists: '排队数据',
        number_of_reservations: '预约数据',
        number_of_new_guests: '新客数据',
        daily: '每日',
        weekly: '每周',
        monthly: '每月',
        yearly: '每年',
        currently_waiting: '当前排队',
        total_waitlist: '总排队',
        seated_parties: '入座桌数',
        seated_waitlists: '入座排队',
        total_waitlist_days: '总排队数(基于星期)',
        seated_waitlists_days: '入座排队数(基于星期)',
        no_show_days: '过号数(基于星期)',
        waitlist_source: '排队来源',
        via_qr_code: '来自扫码',
        via_tablet: '来自安卓平板',
        via_google: '来自Google',
        via_ipad: '来自iPad',
        via_phone: '来自电话',
        wait_time: '等待时长',
        average_wait_time: '平均等待时长',
        max_wait_time: '最大等待时长',
        wait_time_distribution: '等待时长分布',
        party_size: '用餐人数',
        most_popular_party_type: '最受欢迎的用餐人数',
        waitlists_of_diffenrent_party_sizes: '不同用餐人数的排队情况',
        guest_number_of_party: '用餐人数',
        waitlist_amount: '排队数量',
        guests: '客人',
        waitlists: '排队',
        take_up: '占比',
        today_rez: '今日预定',
        total_seated_rez: '全部入座预定',
        rez_cancelled: '预定取消',
        by_guests: '被客人',
        by_restaurant: '被餐馆',
        today_online_rez: '今日在线预定',
        total_reservations: '全部预定',
        seated_rez_number: '入座预定',
        cancelled_by_restaurant: '被餐厅取消',
        cancelled_by_guests: '被顾客取消',
        reservation_source: '预定来源',
        online_rez: '在线预约',
        reservation_time: '预约时间',
        average_in_advance: '提前多久预约',
        mode: '平均',
        attendance: '出席率',
        total_reservation_number: '总预定数',
        seated_reservation_number: '入座预定数',
        by_days: '(基于星期)',
        day: '天',
        reservation_of_diffenrent_party_sizes: '不同用餐人数的预定情况',
        reservation_amount: '预定数量',
        total_count: '总数',
        total_message_count: '短信总数',
        total_segments_count: '短信分段总数',
        total_voice_count: '语音总数',
        notification_log: '通知日志',
        sending: '发送中',
        delivered: '已送达',
        failed: '失败',
        from_phone: '发送手机号',
        to_phone: '目标手机号',
        segments: '分段数',
        all_store: '全部店铺',
        go_back: '返回',
        store_list: '商户列表',
    },
    locations: {
        locations: '地址',
        logo: 'Logo',
        nickname: '昵称',
        address: '地址',
        city: '城市',
        country: '国家',
        edit_nickname: '编辑昵称',
    },
    survey: {
        survey: '问卷+',
        food_star: '菜品星级',
        service_star: '服务星级',
        menu_star: '菜单星级',
        speed_star: '速度星级',
        is_first_time: '是否首次',
        frequency: '来访频率',
        known_method: '了解方式',
        favorite_food: '最喜欢的菜',
        suggestion: '建议',
        note: '商户备注',
    }
};